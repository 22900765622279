.loginPageWrapper {
  display: flex;
  flex-direction: row;
}

.welcomeSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-image: url('../../../public/login_background_image.png'); // Assuming image is in public folder
  background-size: cover;
  background-position: center;
}

.welcomeText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.welcomeSubtitle,
.welcomeTitle {
  font-family: 'Outfit', sans-serif;
  color: white;
}

.welcomeSubtitle {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.welcomeTitle {
  font-size: 2.5rem;
  margin-top: 1rem;
}

.loginSection {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100vh;
  background-color: #f5f5f5;
}

.logoSection {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.poweredLogo {
  width: 90px;
  padding: 20px;
}

.loginContent {
  display: flex;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.loginBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  width: 45%;
}

.loginHeading {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: flex-start;
  text-align: left;
}

.googleSignInBtn {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .loginPageWrapper {
    flex-direction: column;
    height: 100vh;
  }

  .welcomeSection {
    width: 100%;
    height: 40%;
  }

  .welcomeImage {
    height: 100%;
    object-fit: cover;
  }

  .welcomeText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .welcomeSubtitle {
    font-size: 1rem;
  }

  .welcomeTitle {
    font-size: 2rem;
  }

  .loginSection {
    display: flex;
    height: 60%;
    width: 100%;
    flex-direction: column-reverse;
  }

  .logoSection {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .loginContent {
    width: 70%;
    height: 80%;
    display: flex;
    justify-content: flex-start;
  }

  .loginBox {
    display: flex;
    width: 90%;
    margin-top: 10%;
    justify-content: flex-start;
  }

  .loginHeading {
    display: flex;
    justify-content: flex-start;
    text-align: left;
  }
  .googleSignInBtn {
    display: flex;
    justify-content: left;
  }
}
