.searchBar {
    padding: 15px 50px;
    background-color: #fff;
    border: 1px solid #777777;
    width: 100%; /* Takes full width of the container */
    box-sizing: border-box;
    border-radius: 8px;
    height: auto;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    @media (max-width: 768px) {
      height: 42px;
    }
  }

  .searchBarContainer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    @media (max-width: 768px) {
      width: 90%;
        margin-left: 5px;
        margin-right: 5px;
      }
  }

  .search-icon {
    position: absolute;
    left: 22px;
    color: #777777;
  }