.container {
  padding: 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-self: center;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  height: auto;
  @media (max-width: 768px) {
    max-width: 100vw;
    overflow-x: hidden;
  }
}

.jobsContainer {
  display: flex;
  flex-direction: column;
  height: calc(100% - 20px);
  flex-grow: 1;
  width: 100%;
  overflow-x: hidden;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.jobBoardTitle {
  display: flex;
  align-items: end;
  margin: 20px 0 0 30px;
  color: #00558A;
  font-size: 1.1rem;
}

.jobBoardLogoContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: 768px) {
    display: none;
  }
}