.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // or any height you prefer
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
