.main {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
}

.mainLogo {
  display: flex;
  justify-self: end;
  width: 95px;
  height: 27px;
  margin: 20px 30px 0px 0px;
}

.mainDashLogo {
  display: flex;
  justify-self: end;
  width: 95px;
  height: 27px;
  margin-top: 20px;
  margin-right: 30px;
}

.logoContainer{
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
}