.usersMainContainer {
  display: flex;
  flex-grow: 1;
  margin: 0px;
  min-width: 100%;
  min-height: 100%;
}

.usersContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px;
}

.userTitle {
  // display: inline;
  margin: 0px 0px 0px 30px;
  color: #00558A;
  }

.userlogoContainer {
  margin: 0px;
  width: 100%;
}

.usersWrapper {
  margin: 30px 20px;
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.usersTitle {
  margin: 20px 0px 0px 30px;
  color: #00558A;
  font-size: 17.6px;
}

.jobBoardUserLogoContainer
{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
  flex-direction: row;
  @media (max-width: 768px) {
    display: none;
  }
}