.savedContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  & .savedJobsHeader {
    width: fit-content;
    margin: 0;
    font-size: 30px;
    margin: 0px;
    padding: 0px;
    width: 100%;
  }
}

.mainSavedJobsLogo {
  display: flex;
  align-self: flex-end;
  width: 95px;
  height: 27px;
  margin: 20px 30px 0px 0px;

}

@media (max-width: 768px) {
  .savedContainer {
    width: 100%;
    padding: 0;
  }
}

.starredList {
  display: flex;
  flex-direction: column;
  max-height: 90vh;

  @media (max-width: 768px) {
    .starredList {
      max-height: calc(100vh - 200px); 
      overflow-y: scroll; 
    }
}
}