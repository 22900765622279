$sidebarWidthOpen: 15%;
$sidebarWidthClosed: 2.5%;
$backgroundColorOpen: #ffffff;
$backgroundColorClosed: #ffffff;
$activeBackgroundColor: #00558a;
$activeTextColor: #ffffff;
$defaultTextColor: #4a4a4a;
$hoverBackgroundColor: #777777;

.overlay {
  display: none; 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 9; 
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: $sidebarWidthClosed;
  padding: 1rem;
  background-color: $backgroundColorClosed;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border-right: .5px solid #e0e0e0;
  position: relative;

  &.open {
    width: $sidebarWidthOpen;
    background-color: $backgroundColorOpen;
  }

  &.closed {
    width: $sidebarWidthClosed;
    background-color: $backgroundColorClosed;

    .title,
    .sidebar-item span,
    .logout span {
      display: none;
    }

    .sidebar-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        display: flex !important;
        flex-direction: center !important;
        width: 1rem;
        height: 1rem;
        margin: 0;
        padding: 5%;
        margin-right: 0rem !important;
      }

    }

    .sidebar-footer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      cursor: pointer;

      .logout {
        display: flex;
        flex-direction: column;
        align-items: center;
  

        .icon {
          display: flex !important;
          flex-direction: center !important;
          width: 1rem;
          height: 1rem;
          margin: 0;
          padding: 5%;
          margin-right: 0rem !important;
        }
      }
    }

    .sidebar-header {
      display: flex;
      justify-content: center;
      margin-bottom: 20%;
    }
  }

.sidebar-header .sidebar-logo {
  display: none;
}

  .sidebar-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 3%;

    .title {
      font-size: 1.3rem;
      color:#171717;
      font-weight: 600;
      white-space: nowrap;
    }


    .toggle-icon {
      display: none;
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 1.6rem;

      .hamburger-icon {
        display: none; 
      }

      .close-icon {
        display: none;
      }

      .arrow-icon {
        display: flex; 
      }
    }
  }

  .sidebar-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 0.5rem;

    .sidebar-item {
      display: flex;
      padding: .8rem 1rem;
      border-radius: 0.5rem;
      cursor: pointer;
      color: $defaultTextColor;
      transition: background-color 0.2s, color 0.2s;

      .icon {
        width: 1rem;
        height: 1rem;
        margin-right: 1rem;
        display: flex;
      }

      span {
        font-size: .8rem;
      }

      &.active {
        background-color: $activeBackgroundColor;
        color: $activeTextColor;

        .icon {
          fill: $activeTextColor;
        }
      }

      &:hover {
        background-color: $hoverBackgroundColor;
        color: $activeTextColor;
      }
    }
  }

  .sidebar-footer {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    margin-top: auto;

    .logout {
      display: flex;
      padding: .8rem 1rem;
      border-radius: 0.5rem;
      cursor: pointer;
      color: $defaultTextColor;
      transition: background-color 0.2s, color 0.2s;

      .icon {
        width: 1rem;
        height: 1rem;
        margin-right: 1rem;
        display: flex;
      }

      span {
        font-size: .8rem;
      }

      &:hover {
        background-color: $hoverBackgroundColor;
        color: $activeTextColor;
      }
    }
  }
}

// Media Query for Tablet View (768px - 1024px)
@media (max-width: 1250px) {
  .sidebar {
    width: 12%;
    padding: 0.75rem;

    &.open {
      width: 15%;
    }

    &.closed {
      width: 4%;
    }

    .sidebar-header {
      .title {
        font-size: 1rem;
        flex-basis: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .sidebar-content {
      gap: 0.5rem;

      .sidebar-item {
        padding: 0.5rem;

        .icon {
          width: 1rem;
          height: 1rem;
        }

        span {
          font-size: 0.75rem;
        }
      }
    }

    .sidebar-footer {
      display: flex;
      margin-top: auto;
  
      .logout {
        padding: 0.5rem;
  
        .icon {
          width: 1rem;
          height: 1rem;
        }

        span {
          font-size: 0.75rem;
        }
  
        &:hover {
          background-color: $hoverBackgroundColor;
          color: $activeTextColor;
        }
      }
    }
  }
}

// Media Query for Mobile View (up to 767px)
@media (max-width: 767px) {

  .sidebar.open ~ .overlay {
    display: block; 
  }

  .sidebar {
    padding: 0.5rem;
    position: fixed;
    z-index: 10;
    height: 100%;
    left:auto; 
    right: 0;

    &.open {
      width: 65%;

      .sidebar {
        display: block; 
        position: fixed; 
          top: 1%;
        right: 15%;
      }

      .sidebar-header {
        display: flex;

        .title {
          display: none;
        }
        
        .arrow-icon {
          display: none;
        }
        .close-icon {
          display: block;

        }
        .sidebar-logo{
          display: flex;
          width: 65%;  
          height: auto;  
          padding-left: 1rem;
          margin-top: 5%;
          margin-bottom: 2%;
        }
      }

      .sidebar-content {
        padding-left: 1rem;
        padding-right: 1rem;
        gap: 0.5rem;

        .sidebar-item {
          padding: 0.9rem;

          .icon {
            width: 0.875rem;
            height: 0.875rem;
          }

          span {
            font-size: 0.8rem;
          }
        }
      }

      .sidebar-footer {
        display: flex;
        padding: 5%;
        padding-left: 1rem;
        padding-right: 1rem;
        gap: 0.5rem;
        margin-top: auto;
      

        .logout {
          padding: 0.9rem;

          .icon {
            width: 0.875rem;
            height: 0.875rem;
          }

          span {
            font-size: 0.8rem;
          }

        }
      }
    }

    &.closed {
      width: 0 !important;
      padding: 0 !important; 
      overflow: hidden; 
    
      .sidebar-content,
      .sidebar-footer {
        display: none; 
      }

      .toggle-icon {
        display: block; 
        position: fixed; 
        right: 10%;
        top: 0.2%;
        z-index: 5; 
        cursor: pointer;

        .hamburger-icon {
          display: block;
        }
      
        .arrow-icon {
          display: none; 
        }
      }
      
    }
  }
}
