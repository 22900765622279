$bgColorDoc: #f5f7f8;
$bgColorEl: #fff;
$hoverColor: #DDEAF4;



.profile {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-flow: column nowrap;
  }
}

.sidebar {
  flex-shrink: 0; 
}


.userCardWrapper {
  display: flex;
  flex-flow: column nowrap;
  min-width: 300px;
  width: min-content;
  padding-top: 2%;
  padding-left: 3%;
  margin: 0 auto;
  align-items: center;

  @media (max-width: 768px) {
    flex-flow: row wrap;
    width: calc(100% - 40px);
    column-gap: 40px;
    padding: 20px 20px;
    min-width: 250px;
  }

  & .profileCard {
    position: relative;
    min-width: 200px;
    width: calc(100% - 40px);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    row-gap: 5px;
    padding: 50px 20px;
    background-color: $bgColorEl;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    & .profileImg {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      border: 1px solid black;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    & .profileTitle {
      margin: 5px 0;
      font-weight: bold;
      color: black;
    }

    & .displayNameInput {
      margin: 1px 0;
      height: 25px;
      font-weight: regular;
      border: 1px solid gray;
      text-align: center;
      background-color: unset;
      padding: 5px 5px;
      font-size: 24px;
      width: calc(100% - 40px);

      &::placeholder {
        color: gray;
        font-weight: regular;
      }
    }

    & .profileSubtitle {
      margin: 0;
      color: gray;
      font-weight: normal;
      font-size: 16px;
    }

    & .jobTitleInput {
      margin: 0;
      height: 25px;
      border: 1px solid gray;
      text-align: center;
      background-color: unset;
      color: gray;
      padding: 3px 5px;
      font-size: 16px;
      width: calc(100% - 60px);

      &::placeholder {
        color: lightgray;
      }
    }

    & .editMenuBtn {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;

      & .editMenuWrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 1px solid transparent;

        &:hover,
        &:focus {
          border: 1px solid gray;
        }
      }

      & .editMenu {
        display: flex;
        flex-flow: column nowrap;
        justify-content: left;
        align-items: center;
        position: absolute;
        top: 100%;
        right: 0;
        max-height: 0;
        margin-top: 10px;
        overflow: hidden;
        background-color: $bgColorEl;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        transition: 0.2s max-height;

        &.open {
          max-height: 100px;
        }

        & .editOption {
          width: max-content;
          min-width: calc(100% - 32px);
          padding: 10px 16px;
          transition: 0.3s all;
          display: flex;
          justify-content: left;
          column-gap: 7px;

          &:hover {
            background-color: lightgray;
          }

          & .optionIcon {
            width: 16px;
          }
        }
      }

      & .saveBtn {
        position: absolute;
        border: 1px solid gray;
        background-color: unset;
        cursor: pointer;
        right: 0;
        padding: 5px 10px;
        border-radius: 5px;
        transition: 0.3s all;

        &:hover {
          background-color: $hoverColor;
        }
      }
    }
  }

  .profileTitle {
    color: gray;
    font-weight: normal;
    // padding-bottom: 5px;
    // border-bottom: 1px solid gray;
  }

  & .listSwitcher {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    row-gap: 10px;
    text-align: center;

    & .switchBtn {
      display: flex;
      flex-flow: row nowrap;
      white-space: nowrap !important;
      justify-content: space-between !important;
      background-color: $bgColorEl;
      border-radius: 8px;
      padding: 10px 15px;
      transition: 0.3s all;
      cursor: pointer;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

      &:hover {
        background-color: $hoverColor;
      }

      &.active {
        background-color: #00558A;
        color: white;
      }
    }
  }
}

.profile-header {
  display: absolute;
  top: 100px;
  color: #00558A;
  font-size: 1.1rem; 
}


.jobListWrapper {
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  width: auto;
  min-width: 55%;
  min-height: 55%;
}

.statusMessage {
  display: block;
  visibility: hidden;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease;
  z-index: 1;
}

.categorySeparator {
  display: flex;
  border: none;
  height: 1.5px;
  background-color: #777777;
  border-radius: 10px; 
  width: 80%;
  margin-top: 10%;
  margin-bottom: 5%;
}

.mainLogo {
  display: flex;
  align-self: end;
}


@media (max-width: 768px) {
  .profile {
    overflow-x: hidden; 
    overflow-y: auto;  
  }
}