.filtersContainer {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem 1rem;
  margin-top: 20px;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
    flex-wrap: wrap;
    justify-content: baseline;
    flex-direction: column;
  }
}

.filter-btn {
  display: flex;
  flex: 0 0 auto;
  border-radius: 8px;
  min-height: 20px;
  background-color: #ffffff;
  color: #171717;
  padding: 10px 15px;
  align-items: center;
  justify-content: center;
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  gap: 5px;

  &:hover,
  &.open {
    border: 1px solid #00558a;
    background-color: #ddeaf4;
    color: #00558a;
  }

  &.users {
    background-color: white;
  }
  & .menuIcon {
    width: 12px;
    height: auto;
    transition: all 0.3s ease;
    display: none;
    cursor: pointer;

    &.active {
      display: block;
    }
  }
  &.reset {
    color: #126393;
    background-color: transparent;
    border-radius: 8px;
    min-height: 20px;
    font-size: 0.9rem;
    cursor: pointer;
    border: 1px solid transparent;
    gap: 5px;
    width: 6rem;
  }

  & .filter-btn-title {
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: center;
  }
}

.filter-items {
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: block;
  padding: 0 5px;
  margin-top: 10px;
  max-width: 300px;
  max-height: 0;
  transition:
    max-height 0.2s,
    padding 0.2s;
  overflow: hidden;

  &.open {
    max-height: 500px;
    padding: 5px 5px;
  }

  & .searchOptions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ccc;
    margin: 5px 5px;
    width: calc(100% - 10px);
    border-radius: 5px;

    & .searchInput {
      min-height: 25px;
      border: unset;
      background-color: unset;
      outline: 0;
      width: 100%;
      margin: 0 5px;
    }

    & .xIcon {
      width: 15px;
      height: 15px;
      display: none;
      color: rgb(75, 75, 75);
      cursor: pointer;
      margin: 0 5px;

      &.show {
        display: block;
      }
    }
  }

  & .filterOptions {
    list-style: none;
    padding: unset;
    margin: 5px 0;
    max-height: 300px;
    overflow: auto;

    & .italicized {
      font-style: italic;
      color: gray;
      font-size: 10px;
      margin: 0 10px;
    }

    & .filter-item {
      display: flex;
      flex-flow: row nowrap;
      list-style: none;

      & .checkInput {
        display: none;
      }

      & .checkInput:checked + .checkLabel .checkBox .checkIcon {
        display: block;
      }

      & .checkLabel {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        transition: all 0.3s;
        padding: 7px 10px;
        width: 100%;
        border-radius: 10px;

        &:hover {
          background-color: rgb(238, 238, 238);
          cursor: pointer;
        }

        & .level {
          padding: 2px 5px;
          border-radius: 5px;
          text-transform: capitalize;

          &.admin {
            background-color: rgb(255, 160, 160);
          }

          &.member {
            background-color: rgb(238, 238, 238);
          }
        }
      }

      & .checkBox {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        width: 16px;
        min-width: 16px;
        border-radius: 4px;
        margin-right: 12px;
        border: 1.5px solid rgb(194, 194, 194);
        transition: all 0.3s ease-out;
        cursor: pointer;

        & .checkIcon {
          display: none;
        }
      }
    }
  }
}
