.job-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.job-listing {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  background-color: white;
  border-radius: 15px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.job-details {
  background-color: white;
}

.mobile-details {
  display: none;
}

h5 {
  color: #138275;
   margin: 0px;
}

h4 {
  font-weight: 400;
  margin: 0px;
}

h3 {
  font-weight: 600;
  margin: 0px;
}

a {
  color: #138275;
  text-decoration: none;
  font-weight: 700;
}

.title-container {
  margin: 10px;
}

.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  
}

.time-commitment {
  color: #55565B;
  font-size: 15px;
}

.short-desc {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  
  
}

.job-link-applied {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
}

.toggleDetailsText {
  color: #00558A;
  text-decoration: underline;
}

.applied-toggable {
  display: flex;
  width: 100px;
  height: 25px;
  font-weight: 700;
  border: #000000 solid 1px;
  border-radius: 16px;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.applied-text {
  padding-bottom: 2px;
}

.applied-toggable.clicked {
  background-color: #bde3d0;
}

.applied-toggable:hover {
  cursor: pointer;
}

@media (max-width: 1024px) {
  .title {
    width: 25%;
  }
  .location {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .location {
    display: none;
  }
  h4 {
    display: none;
  }
  .title {
    width: 100%;
  }
  .mobile-details {
    display: block;
  }

  .job-listing-title {
    width: 80%;
  }
}
