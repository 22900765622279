.page-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  background-color: #f4f4f4;
  color: #333;

  h1 {
    font-size: 4rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.5rem;
  }

  button {
    margin-top: 1rem;
    padding: 0.7rem 1rem;
    font-size: 1rem;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}
