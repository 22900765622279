.paginationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 10px;
  transition: 0.2s background-color;
  padding: 0px 30px;
}

.paginationControls {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.paginationButton {
  padding: 8px 12px;
  margin: 0 5px;
  border: none;
  background-color: #7A9BAE;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background-color: #00558A;
  }
}

.paginationButton.active {
  background-color: #00558A;
  cursor: default;
}

.paginationEllipsis {
  padding: 8px 12px;
  border: 1px solid #00558A;
  margin: 0 5px;
  background: transparent;
  color: #666666;
  cursor: default;
}

.queryList {
  width: 100%;

  &.users {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin: 0 auto 50px;
  }

  @media (max-width: 1200px) {
    width: calc(100% - 60px);
    width: 100%;
    // padding: 20px 30px;
  }
}


