.userCard {
  display: flex;
  flex-flow: row nowrap;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  min-width: 250px;
  max-width: 300px;
  justify-content: left;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s all;
  padding: 10px 20px;

  &:hover {
    transform: translateY(-4px);
  }

  & .profileImg {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    border: 1px solid black;
    margin: 10px 15px 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  & .userDetails {
    display: flex;
    flex-direction: column;
    gap: 5px;

    & .name {
      display: flex;
      column-gap: 5px;
      font-size: 16px;
      align-items: center;

      & .userLevel {
        padding: 2px 5px;
        background-color: rgb(255, 160, 160);
        border-radius: 5px;
        text-transform: capitalize;
      }
    }

    & .jobTitle {
      color: gray;
    }
  }
}
