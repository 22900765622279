.searchContainer {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: 0.2s all;
  margin-top: 20px;
  align-items: center;
  gap: 1rem;

  & .filtersContainer {
    flex-flow: row nowrap;
    justify-content: flex-start;
    gap: 1rem;
    margin: 0 15px 0 10px;
  }

  @media (max-width: 768px) {
    background-color: rgb(243, 243, 243);
    flex-direction: row;
    justify-content: left;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: unset;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 6px 0px;

    & .filtersContainer {
      flex-flow: row nowrap;
      justify-content: center;
      gap: 1rem;
      margin: 5px 0px;
    }
  }
}

.clearButton {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: red;
}

.filter-dropdown.categories {
  display: none;
}

.jobCount {
  align-self: first baseline;
  margin: 0;
  @media (max-width: 768px) {
    display: none;
  }
}
